
import './App.css';
import Header from './components/Header';



function App() {
  return (
    <div className="App">
      <Header />
      <div class="video-background">
        <div class="video-foreground">
          <video autoPlay loop muted playsInline>
            <source src="https://mm-external.s3.amazonaws.com/clearing-stumps.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
        <div class="video-overlay">
          <h1>We Remove Stumps Fast</h1>
        </div>
      </div>

      <section class="container my-5">
        <h2 class="text-center mb-4">Find Professionals in Your Town</h2>
        <div class="row">
          <div class="col-md-4">
            <h3>Stump Grinding</h3>
            <p>Our team of professionals will remove any stump from your property quickly and efficiently.</p>
          </div>
          <div class="col-md-4">
            <h3>Tree Removal</h3>
            <p>Need a tree removed? We can help. Our team is experienced and ready to help you.</p>
          </div>
          <div class="col-md-4">
            <h3>Land Clearing</h3>
            <p>Need to clear a lot? We can help with that too. Our team is ready to help you.</p>
          </div>
        </div>
      </section>

      <section class="container my-5">
        <h2 class="text-center mb-4">Testimonials</h2>
        <div class="row">
          <div class="col-md-4">
            <blockquote class="blockquote">
              <p>"CT Stump Grinders removed a massive stump from my backyard in no time. Highly recommend!"</p>
              <footer class="blockquote-footer">Jane Doe</footer>
            </blockquote>
          </div>
          <div class="col-md-4">
            <blockquote class="blockquote">
              <p>"Professional, quick, and clean. The best in Connecticut!"</p>
              <footer class="blockquote-footer">John Smith</footer>
            </blockquote>
          </div>
          <div class="col-md-4">
            <blockquote class="blockquote">
              <p>"I've used their services twice, and they've been fantastic each time. Great value."</p>
              <footer class="blockquote-footer">Emily Johnson</footer>
            </blockquote>
          </div>
        </div>
      </section>

      <footer class="text-center p-4">
        CT STUMP GRINDERS<br />
        Contact us: (123) 456-7890<br />
        Email: info@ctstumpgrinders.com
      </footer>

    </div>
  );
}

export default App;
